import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import appBackgroundImage from '../../styles/images/trivia_background.png'
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import sampleAppTopImage from '../../styles/images/top_icon.png'
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import { Modal } from 'reactstrap';
import { PhotoshopPicker } from 'react-color';
import RichTextEditor from "html-verify-react-rte";


class SetUpTeamVariables extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            tenantVariables: {},
            showTabOne: true,
            showTabTwo: false,
            showTabThree: false,
            loading:true,
            colorToShow: "#ffffff",
            colorToUpdate: "",
            colorPickerShow: false,
            colorsTab: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentDidMount() {
      this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
        context: this,
        state: 'tenantVariables',
      });
      this.setState({
        loading: false
      })
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    handleSubmit(event) {
      event.preventDefault();
        const variables = this.state.tenantVariables;
        let afterFinishPredictingBody = variables['afterFinishPredictingBody'] || RichTextEditor.createEmptyValue();
        afterFinishPredictingBody = afterFinishPredictingBody.toString('html');
        if(afterFinishPredictingBody === "<p><br></p>"){
            afterFinishPredictingBody = "";
        }
        variables['afterFinishPredictingBody'] = afterFinishPredictingBody;
      let vm = this;
      base.post("tenantVariables/", {
        data: variables,
        then(err){
          vm.setState({loading:false})
          if(!err){
            swal({
                title: 'Tenant Variables Updated!',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
              })
          } else {
            swal({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try logging out and logging back in',
                type: 'error',
                confirmButtonText: 'Ok'
              })
            console.log(err)
          }
        }
      })
    }

    handleChange (evt) {
      let tenantVariablesCopy = this.state.tenantVariables;
      tenantVariablesCopy[evt.target.name] = evt.target.value
      this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    handleImageChange(event){
      let name_of_file = event.target.name;
      let target = this[name_of_file];
      let file_to_update =target.files[0];
      this.setState({loading:true})
      let vm = this;
      this.UploadImage.upload_file(file_to_update).then(res => {
          vm.setState({loading:false})
          if(res.error){
              swal({
                  title: 'Image cannot be uploaded',
                  text: res.error,
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          } else if(res.imageUrl) {
              let tenantVariablesCopy = vm.state.tenantVariables;
              tenantVariablesCopy[name_of_file] = res.imageUrl
              vm.setState({
                  tenantVariables: tenantVariablesCopy,
              }, () => {
                  vm[name_of_file].value = ""
              });
          } else {
              swal({
                  title: 'Image cannot be uploaded',
                  text: "Something went wrong, please re-upload your image and try again!",
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          }
      })
    }

    switchTextShowing(element){
      if(element === "first"){
        document.getElementById('showfirst').classList.add('active');
        document.getElementById('showthird').classList.remove('active');
        this.setState({
          showTabOne: true,
          showTabTwo: false,
          showTabThree: false,
        })
      }else if(element === "second"){
        document.getElementById('showfirst').classList.remove('active');
        document.getElementById('showthird').classList.remove('active');
        this.setState({
          showTabOne: false,
          showTabTwo: true,
          showTabThree: false,
        })
      } else {
        document.getElementById('showfirst').classList.remove('active');
        document.getElementById('showthird').classList.add('active');
        this.setState({
          showTabOne: false,
          showTabTwo: false,
          showTabThree: true,
        })
      }
    }

    openColorPicker(colorToChange){
        let oldColor = this.state.tenantVariables[colorToChange] || '#fff';
        this.setState({
            colorPickerShow: true,
            colorToShow: oldColor,
            colorToUpdate: colorToChange
        })
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        })
    };

    acceptPicker(){
        let colorToChange = this.state.colorToUpdate;
        let tenantVariables = this.state.tenantVariables;
        tenantVariables[colorToChange] = this.state.colorToShow;
        this.setState({
            tenantVariables: tenantVariables,
            updateHappend: true,
            colorPickerShow: false,
        })
    }

    dismissPicker(){
        this.setState({
            colorPickerShow: false,
        })
    }

    toggleColors(){
        if(this.state.colorsTab){
            document.getElementById('showColors').classList.remove('active');
            document.getElementById('showImages').classList.add('active');
        } else {
            document.getElementById('showColors').classList.add('active');
            document.getElementById('showImages').classList.remove('active');
        }
        this.setState({colorsTab: !this.state.colorsTab})
    }

    handleRichTextChange = (name, text) => {
        let tenantVariablesCopy = this.state.tenantVariables;
        tenantVariablesCopy[name] = text;
        this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    render() {
        let backgroundImage = this.state.tenantVariables.backgroundImage || appBackgroundImage
        let frontImage = this.state.tenantVariables.frontLogoImage || sampleAppFrontImage
        let teamLogo = this.state.tenantVariables.topLeftImage || sampleAppTopImage
        let afterFinishPredictingBody = this.state.tenantVariables.afterFinishPredictingBody || RichTextEditor.createEmptyValue();
        if(typeof afterFinishPredictingBody === 'string') {
            afterFinishPredictingBody = RichTextEditor.createValueFromString(afterFinishPredictingBody, 'html')
        }
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <p className="admin-header-text" style={{marginBottom:0, marginTop:'5px'}}>Game Branding</p>
                <p className="admin-subheader-text">This is where you can edit the game branding on your activation</p>
                <div className="container-out">
                  <div className="admin-form-box" style={{marginTop:'0px', paddingTop:'5px'}}>
                    <form onSubmit={this.handleSubmit} id="create-game-form">
                      <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                      <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                          <li className="nav-item" onClick={()=> this.switchTextShowing('first')}>
                              <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showfirst">
                                  <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Fan Color/Images</span>
                              </a>
                          </li>
                          <li className="nav-item" onClick={()=> this.switchTextShowing('third')}>
                              <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showthird">
                                  <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Game Text</span>
                              </a>
                          </li>
                      </ul>
                    <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{display: this.state.showTabOne === true ? '' : 'none'}}>
                        <li className="nav-item" onClick={()=> this.toggleColors()}>
                            <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showColors">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-none d-lg-block">Colors</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={()=> this.toggleColors()}>
                            <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showImages">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-none d-lg-block">Images</span>
                            </a>
                        </li>
                    </ul>
                      <div style={{display: this.state.showTabOne ? 'block' : 'none'}}>
                          <div style={{display: this.state.colorsTab ? 'block': 'none'}}>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4>Primary Button Colors</h4>
                                    <div className="form-inline">
                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("primaryColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.primaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="primaryColor" style={{marginRight: 10}}>Background Color</label>
                                        <input id="primaryColor" name="primaryColor" type="text" className="form-control" value={this.state.tenantVariables.primaryColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                        <div style={{height:'10px', width:'100%'}}/>

                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("secondaryColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.secondaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="secondaryColor" style={{marginRight: 10}}>Text Color</label>
                                        <input id="secondaryColor" name="secondaryColor" type="text" className="form-control" value={this.state.tenantVariables.secondaryColor} onChange={this.handleChange} placeholder="#000"/>

                                        <div style={{height:'10px', width:'100%'}}/>

                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("buttonOutlineColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.buttonOutlineColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="buttonOutlineColor" style={{marginRight: 10}}>Outline Color</label>
                                        <input id="buttonOutlineColor" name="buttonOutlineColor" type="text" className="form-control" value={this.state.tenantVariables.buttonOutlineColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h4>Log Out Colors</h4>
                                    <div className="form-inline">
                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("logOutButtonColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.logOutButtonColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="logOutButtonColor" style={{marginRight: 10}}>Log Out Button Color</label>
                                        <input id="logOutButtonColor" name="logOutButtonColor" type="text" className="form-control" value={this.state.tenantVariables.logOutButtonColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                    </div>
                                </div>
                            </div>

                              <div style={{height:'10px', width:'100%'}}/>

                              <div className="row">
                                  <div className="col-md-6">
                                      <h4>Other Colors</h4>
                                      <div className="form-inline">
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("sliderColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.sliderColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="sliderColor" style={{marginRight: 10}}>Slider Color</label>
                                          <input id="sliderColor" name="sliderColor" type="text" className="form-control" value={this.state.tenantVariables.sliderColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                          <div style={{height:'10px', width:'100%'}}/>

                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("questionColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.questionColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="questionColor" style={{marginRight: 10}}>Question Color</label>
                                          <input id="questionColor" name="questionColor" type="text" className="form-control" value={this.state.tenantVariables.questionColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div style={{display: this.state.colorsTab ? 'none' : 'block'}}>
                        <div className="admin-grid-container three-columns" style={{float:'left', display: 'flex', alignItems: 'flex-end'}}>
                          <div className="form-group" style={{textAlign:'center', margin:20}}>
                            <img src={backgroundImage} width="160" height="auto" alt=""/>
                            <br/>
                            <label htmlFor="backgroundImage">Phone Background Image<br/>(1654px X 926px)</label>
                            <div className="form-group">
                              <input style={{display:'none'}} id="backgroundImage" name="backgroundImage" type="file" ref={input => {this.backgroundImage = input; }} onChange={this.handleImageChange}/>
                              <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('backgroundImage').click()} />
                            </div>
                          </div>
                          <div className="form-group" style={{textAlign:'center', margin:20}}>
                            <img src={frontImage} width="150" height="auto" alt=""/>
                            <br/>
                            <label htmlFor="frontLogoImage">Front Logo<br/>(620px X 732px)</label>
                            <div className="form-group">
                              <input style={{display:'none'}} id="frontLogoImage" name="frontLogoImage" type="file" ref={input => {this.frontLogoImage = input; }} onChange={this.handleImageChange}/>
                              <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('frontLogoImage').click()} />
                            </div>
                          </div>
                        </div>
                        <div className="admin-grid-container three-columns" style={{float:'left', display: 'flex', alignItems: 'flex-end'}}>
                          <div className="form-group" style={{float:'left', textAlign:'center', margin:20}}>
                            <img src={teamLogo} width="100" height="auto" alt=""/>
                            <br/>
                            <label htmlFor="topLeftImage">Top Logo<br/>(398px X 470px)</label>
                            <div className="form-group">
                              <input style={{display:'none'}} id="topLeftImage" name="topLeftImage" type="file" ref={input => {this.topLeftImage = input; }} onChange={this.handleImageChange}/>
                              <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('topLeftImage').click()} />
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                      <div style={{display: this.state.showTabThree ? 'block' : 'none'}}>
                          <div className="form-group">
                              <label htmlFor="pageTitle">Page Title:</label>
                              <p className="text-muted" style={{fontSize:12}}>This is the text that shows up in the browser tab in your app and on the web (Ex. Team Prediction)</p>
                              <input id="pageTitle" name="pageTitle" type="text" className="form-control" value={this.state.tenantVariables.pageTitle} onChange={this.handleChange} placeholder="Prediction"/>
                          </div>
                          <hr/>
                        <div className="form-group">
                          <label htmlFor="playingText">Holding Screen Text:</label>
                            <p className="text-muted" style={{fontSize:12}}>This is the text that shows up on the holding page if the game is not active. It should finish the sentence: The next game starts....</p>
                          <input id="playingText" name="playingText" type="text" className="form-control" value={this.state.tenantVariables.playingText} onChange={this.handleChange} placeholder="2nd Quarter"/>
                        </div>
                        <hr/>
                        <div className="form-group">
                          <label htmlFor="belowCorrectText">Below Incorrect/Correct Text:</label>
                          <p className="text-muted" style={{fontSize:12}}>This is the text that shows up below highlighted correct/incorrect text after the question has been entered.</p>
                          <input id="belowCorrectText" name="belowCorrectText" type="text" className="form-control" value={this.state.tenantVariables.belowCorrectText} onChange={this.handleChange} placeholder="THE GAME HAS ENDED. COME BACK NEXT GAME!"/>
                        </div>
                        <hr/>
                        <h5>Pop up when a fan is correct & wins a main prize</h5>
                        <p className="text-muted" style={{marginBottom: "0.5rem", fontSize:12}}>This is the message that the fan receives if they answer correctly and win a main prize</p>
                        <div className="form-group">
                            <label htmlFor="winnerPrizeHeader">Header</label>
                            <input id="winnerPrizeHeader" name="winnerPrizeHeader" type="text" className="form-control" value={this.state.tenantVariables.winnerPrizeHeader} onChange={this.handleChange} placeholder="You Win!"/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="winnerPrizeText">Subheader</label>
                          <input id="winnerPrizeText" name="winnerPrizeText" type="text" className="form-control" value={this.state.tenantVariables.winnerPrizeText} onChange={this.handleChange} placeholder="Check your email for a prize!"/>
                        </div>
                        <hr/>
                        <h5>Pop up when a fan is correct but doesn't win a main prize</h5>
                        <p className="text-muted" style={{marginBottom: "0.5rem", fontSize:12}}>This is the message that the fan receives if they answer correctly but don't win a main prize because they have run out (Note they can still win a prize if you have added an other prize to the game)</p>
                        <div className="form-group">
                          <label htmlFor="winnerNoPrizeHeader">Header</label>
                          <input id="winnerNoPrizeHeader" name="winnerNoPrizeHeader" type="text" className="form-control" value={this.state.tenantVariables.winnerNoPrizeHeader} onChange={this.handleChange} placeholder="You Were Correct!"/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="winnerNoPrizeText">Subheader</label>
                          <input id="winnerNoPrizeText" name="winnerNoPrizeText" type="text" className="form-control" value={this.state.tenantVariables.winnerNoPrizeText} onChange={this.handleChange} placeholder="Unfortunately you did not win a prize this time!"/>
                        </div>
                        <hr/>
                        <h5>Pop up when a fan is incorrect</h5>
                        <p className="text-muted" style={{marginBottom: "0.5rem", fontSize:12}}>This is the message that the fan receives if they answer incorrect. (Note they can still win a prize if you have added a losing prize to the game)</p>
                        <div className="form-group">
                          <label htmlFor="missedHeader">Header</label>
                          <input id="missedHeader" name="missedHeader" type="text" className="form-control" value={this.state.tenantVariables.missedHeader} onChange={this.handleChange} placeholder="Oh No!"/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="missedText">Subheader</label>
                          <input id="missedText" name="missedText" type="text" className="form-control" value={this.state.tenantVariables.missedText} onChange={this.handleChange} placeholder="Your prediction was incorrect! Come back soon and try again"/>
                        </div>
                        <hr/>
                        <div className="form-group">
                            <label htmlFor="afterFinishPredictingHeader">After Finishing Predictions Headers:</label>
                            <input id="afterFinishPredictingHeader" name="afterFinishPredictingHeader" type="text" className="form-control" value={this.state.tenantVariables.afterFinishPredictingHeader} onChange={this.handleChange} placeholder="Nice Job!"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="afterFinishPredictingBody">After Finishing Predictions Body:</label>
                            <RichTextEditor toolbarConfig={toolbarConfig} id="afterFinishPredictingBody" name="afterFinishPredictingBody" value={afterFinishPredictingBody} onChange={(text) => this.handleRichTextChange("afterFinishPredictingBody", text)} placeholder="You answered all the predictions"/>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
              <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
                  <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
              </Modal>
         </div>
        );
    }
}

export default SetUpTeamVariables;
