import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import RichTextEditor from 'html-verify-react-rte';

class SetUpTicketEmail extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            emailVariables: '',
            loading:true,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentWillUnmount() {
      base.removeBinding(this.emailVariablesRef);
    }

    componentDidMount() {
      this.emailVariablesRef = base.listenTo(`emailVariables`, {
        context: this,
        state: 'emailVariables',
          then(data){
            let advanced = false;
            if(data && data.sender) {
                advanced = true;
            }
            console.log(data)
              this.setState({
                  loading: false,
                  advanced: advanced,
                  emailVariables: data
              })
          }
      });
    }

    handleChange(event){
        let target = event.target;
        let value = target.value;
        if(target.type === 'checkbox'){
            value = target.checked
            this.setState({ [target.name]: value });
        } else {
            let emailVariablesCopy = this.state.emailVariables;
            emailVariablesCopy[target.name] = value;
            this.setState({ emailVariables:  emailVariablesCopy});
        }
    }

    handleImageChange(event){
      const name_of_file = event.target.name;
      const target = this[name_of_file];
      const file_to_update =target.files[0];
      this.setState({loading:true})
      const vm = this;
      this.UploadImage.upload_file(file_to_update).then(res => {
          vm.setState({loading:false})
          if(res.error){
              swal({
                  title: 'Image cannot be uploaded',
                  text: res.error,
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          } else if(res.imageUrl) {
              let emailVariablesCopy = vm.state.emailVariables;
              emailVariablesCopy[name_of_file] = res.imageUrl
              vm.setState({
                  emailVariables: emailVariablesCopy,
              }, () => {
                  vm[name_of_file].value = ""
              });
          } else {
              swal({
                  title: 'Image cannot be uploaded',
                  text: "Something went wrong, please re-upload your image and try again!",
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          }
      })
    }

    onChangeHelpText = (helpText) => {
        let emailVariablesCopy = this.state.emailVariables;
        emailVariablesCopy["helpText"] = helpText;
        this.setState({ emailVariables:  emailVariablesCopy});
    }

    handleSubmit(event) {
        event.preventDefault();
        const emailVariables = this.state.emailVariables;
        let sender = emailVariables.sender || "";
        let subjectLine = emailVariables.subjectLine || "";
        let helpText = emailVariables.helpText || "";
        let backgroundImage = emailVariables.emailBackgroundImage || "";
        if(helpText === "<p><br></p>"){
            helpText = "";
        } else {
            helpText = helpText.toString('html')
        }
        this.setState({loading:true});
        const updateRulesObject = {};
        updateRulesObject['helpText'] = helpText;
        updateRulesObject['subjectLine'] = subjectLine;
        updateRulesObject['emailBackgroundImage'] = backgroundImage;
        updateRulesObject['sender'] = sender;
        const vm = this;
        base.post("emailVariables/", {
            data: updateRulesObject,
            then(err){
              vm.setState({loading:false})
              if(!err){
                swal({
                    title: 'Email Variables Updated!',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                  })
              } else {
                swal({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists try logging out and logging back in',
                    type: 'error',
                    confirmButtonText: 'Ok'
                  })
                console.log(err)
              }
            }
        })
    }

    render() {
        let sender = this.state.emailVariables.sender || "";
        let subjectLine = this.state.emailVariables.subjectLine || "";
        let helpText = this.state.emailVariables.helpText || "";
        if(typeof helpText === 'string'){
            helpText = RichTextEditor.createValueFromString(helpText, 'html');
        } else if(!helpText) {
            helpText = RichTextEditor.createEmptyValue()
        }
        let emailBackgroundImage = this.state.emailVariables.emailBackgroundImage || "https://react-game-center-uploads.s3.amazonaws.com/f026482fff6c4c7d8e09064ad705aac4a081b113a7064358861f23f80abb5403.png";
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <p className="admin-header-text" style={{marginBottom:0}}>Email Branding</p>
              <p className="admin-subheader-text">This is where you can edit the email sent to fans</p>
              <div className="container-out">
                <div className="admin-form-box">
                  <form onSubmit={this.handleSubmit} id="create-email-form">
                    <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:20}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                    <div className="row col-md-12">
                      <div className="form-group">
                        <label htmlFor="subjectLine">Email Subject Line</label>
                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is the subject line that your fans will see when they receive their winning emails</p>
                        <input id="subjectLine" name="subjectLine" className="form-control" value={subjectLine} onChange={this.handleChange} />
                      </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-3">
                            <label htmlFor="helpText">Help Text</label>
                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is where you can tell fans where to contact you with issues.  (Example: Having issues? Email info@prediction.com for help.)</p>
                            <RichTextEditor toolbarConfig={toolbarConfig} style={{minHeight:'100px'}} id="helpText" name="helpText" value={helpText} onChange={this.onChangeHelpText} />
                        </div>
                        <div className="form-group col-md-9" align="center">
                            <img src={emailBackgroundImage} width="auto" height="100px" alt=""/>
                            <br/>
                            <label htmlFor="backgroundImage">Email Header Image<br/>(700px X 400px)</label>
                            <div className="form-group">
                              <input style={{display:'none'}}  id="emailBackgroundImage" name="emailBackgroundImage" type="file" ref={input => {this.emailBackgroundImage = input; }} onChange={this.handleImageChange}/>
                              <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('emailBackgroundImage').click()} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="showAdvancedSettings">Advanced</label>
                        <br/>
                        <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                    </div>
                    {this.state.advanced &&
                        <div className="form-group">
                            <label htmlFor="sender">Email Sender</label>
                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is the sender your fans will see when they receive their winning emails. BE AWARE: changing the sender could adversely impact delivery rates</p>
                            <input id="sender" name="sender" type="email" className="form-control" value={sender} onChange={this.handleChange} />
                        </div>
                    }

                  </form>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default SetUpTicketEmail
