import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import { base } from '../../base';
import swal from 'sweetalert2';
import '../../styles/css/AdminMain.css';
import UploadImage from "../utils/UploadImage";
import Dropzone from 'react-dropzone'

class SetUpQuestions extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            questionsList: [],
            editingTicket: false,
            selectedQuestion: {},
            currentQuestionTitle: '',
            rewardToEdit: null,
            imageUrl: null,
            modal: false,
            editTicketId: "",
            loading:true,
            typeOfAnswer: 'slider',
            emailImagePreview: {},
            teamOneImagePreview: {},
            teamTwoImagePreview: {}
        };
        // this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        this.questionsListRef = base.syncState(`questionsList`, {
            context: this,
            state: 'questionsList',
            asArray: true
        });
        this.setState({loading:false})
    }

    componentWillUnmount() {
      base.removeBinding(this.questionsListRef);
    }

    handleChange(event){
      this.setState({[event.target.name]: event.target.value});
    }

    onDrop(files, rejected, myArgument) {
        if(rejected.length > 0){
            swal({
                title: 'Image cannot be uploaded',
                text: 'Make sure the image is less than 2mbs and it is an accepted file type',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        this.setState({loading:true})

        this.UploadImage.upload_file(files[0]).then(res => {
            this.setState({loading:false})
            if(res.error){
                swal({
                    title: 'Image cannot be uploaded',
                    text: res.error,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            } else if(res.imageUrl){
                var nameToUpdate = myArgument + "Preview"
                var fileToUpdate = files[0]
                this.setState({
                    [myArgument]: res.imageUrl,
                    [nameToUpdate]: ({
                        fileToUpdate,
                        preview: URL.createObjectURL(fileToUpdate)
                    })
                });
            } else {
                swal({
                    title: 'Image cannot be uploaded',
                    text: "Something went wrong, please re-upload your image and try again!",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    createQuestion() {
      const questionTitle = this.state.currentQuestionTitle || this.state.selectedQuestion.questionText;
      const questionType = this.state.typeOfAnswer;
      const answerImage = this.state.emailImage || this.state.editTicketEmailImage || false;
        const teamOneImage = this.state.teamOneImage || this.state.editTeamOneImage || false;
        const teamTwoImage = this.state.teamTwoImage || this.state.editTeamTwoImage || false;
      const answerLabel = this.state.answerLabel || false;
      const minTypeNumber = this.state.minTypeNumber || false;
      const maxTypeNumber = this.state.maxTypeNumber || false;
      const stepsNumber = this.state.stepsNumber || false;
      if(!questionTitle){
        swal({
            title: "No Prediction",
            text: 'Make sure to fill out the prediction section!',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      if(!questionType){
        swal({
            title: "No Prediction Type",
            text: 'Make sure to fill out the prediction type section!',
            type: 'warning',
            confirmButtonText: 'Ok'
        })
        return;
      }
        if(questionType === "slider" && (!minTypeNumber || !maxTypeNumber || !stepsNumber || !answerImage || !answerLabel)){
            swal({
                title: "Incorrect Options",
                text: 'You need to add a min, a max, an image, and steps for the slider option',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        } else if(questionType === "slider" && stepsNumber === "0") {
            swal({
                title: "Incorrect Options",
                text: 'Steps cannot be 0',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        } else if(questionType === "score" && (!teamOneImage || !teamTwoImage)){
            swal({
                title: "Incorrect Options",
                text: 'You need to add team images to create a score question',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }

      var selectedQuestion = this.state.selectedQuestion;
      if(Object.keys(selectedQuestion).length === 0){
        selectedQuestion = null;
      }
      const vm = this;
      if(!selectedQuestion) {
          base.push('questionsList', {
              data: {questionText: questionTitle, teamOneImage: teamOneImage, teamTwoImage: teamTwoImage, answerMin: minTypeNumber, answerMax: maxTypeNumber, answerSteps: stepsNumber, answerImage: answerImage, answerLabel: answerLabel, questionType: questionType},
              then(err){
                  if(!err){
                      vm.setState({
                          selectedQuestion: {},
                          currentQuestionTitle: "",
                          modal: false,
                          minTypeNumber: null,
                          maxTypeNumber: null,
                          answerLabel: null,
                          stepsNumber: null,
                          editTeamOneImage: null,
                          editTeamTwoImage: null,
                          editTicketEmailImage: null,
                          editingTicket: false,
                          rewardToEdit: null,
                          emailImagePreview: {},
                          teamOneImagePreview: {},
                          teamTwoImagePreview: {}
                      })
                  }
              }
          })
      } else if(selectedQuestion){
          const selectedQuestionId = selectedQuestion.key;
          base.post(`questionsList/${selectedQuestionId}`, {
              data: {questionText: questionTitle, teamOneImage: teamOneImage, teamTwoImage: teamTwoImage, answerMin: minTypeNumber, answerMax: maxTypeNumber, answerSteps: stepsNumber, answerImage: answerImage, answerLabel: answerLabel, questionType: questionType},
              then(err){
                  if(!err){
                      vm.setState({
                          modal: false,
                          selectedQuestion: {},
                          minTypeNumber: null,
                          maxTypeNumber: null,
                          answerLabel: null,
                          stepsNumber: null,
                          editTeamOneImage: null,
                          editTeamTwoImage: null,
                          editTicketEmailImage: null,
                          editingTicket: false,
                          rewardToEdit: null,
                          emailImagePreview: {},
                          teamOneImagePreview: {},
                          teamTwoImagePreview: {}
                      });
                  }
              }
          });
      }
    }

    editQuestion(event){
      event.preventDefault();
      const array = this.state.questionsList;
      const question = array[event.target.value];
      this.setState({
        modal: !this.state.modal,
        currentQuestionTitle: question.questionText,
        selectedQuestion: question,
        minTypeNumber: question.answerMin,
        maxTypeNumber: question.answerMax,
        answerLabel: question.answerLabel,
        typeOfAnswer: question.questionType,
        stepsNumber: question.answerSteps,
        editTeamOneImage: question.teamOneImage,
        editTeamTwoImage: question.teamTwoImage,
        editTicketEmailImage: question.answerImage,
        editingTicket: true,
        rewardToEdit: question,
        emailImagePreview: {},
        teamOneImagePreview: {},
        teamTwoImagePreview: {}
      });
    }

    deleteTicket(e){
      e.preventDefault();
      var array = this.state.questionsList;
      var index = array[e.target.value];
      swal({
            title: 'Delete Prediction?',
            text: 'Are you sure you want to do this?  You will no longer be able to use this prediction in any new games',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result)=>{
          if (result) {
            base.remove('questionsList/' + index.key, function(err) {
              if(err){
                swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            })
          }
        })
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        editingTicket: false,
        selectedQuestion: {},
        currentQuestionTitle: '',
          minTypeNumber: null,
          maxTypeNumber: null,
          answerLabel: null,
          stepsNumber: null,
          editTicketEmailImage: null,
          rewardToEdit: null,
          emailImagePreview: {},
          teamOneImagePreview: {},
          teamTwoImagePreview: {}
      });
    }

    removeFromAnswerArray(index){
      let answerArray = this.state.currentAnswerList;
      answerArray.splice(index, 1)
      this.setState({
        currentAnswerList: answerArray
      })
    }

    render() {
      const questionsList = this.state.questionsList;
      let selectedQuestion = this.state.selectedQuestion;
      const emailImagePreview = this.state.emailImagePreview;
      const teamOneImagePreview = this.state.teamOneImagePreview;
      const teamTwoImagePreview = this.state.teamTwoImagePreview;
      if(Object.keys(selectedQuestion).length === 0){
        selectedQuestion = null;
      }
      return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="card">
                <div className="card-body">
                    <p className="admin-header-text" style={{marginBottom:0}}>Predictions</p>
                    <p className="admin-subheader-text">These are predictions fans will respond to during the game</p>
                    <button className="btn btn-primary btn-lg create-prize-button" style={{fontSize:20,marginLeft:20}} onClick={this.toggle}>Add Prediction</button>
                    <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                      {
                        questionsList.map(function(item,i){
                            let questionType = item.questionType;
                            if(questionType === "slider"){
                                questionType = "Slider";
                            } else if(questionType === "write_in"){
                                questionType = "Write In"
                            } else if(questionType === "time"){
                                questionType = "Time"
                            } else if(questionType === "score"){
                                questionType = "Game Score"
                            }
                          return <div key={i} className="card">
                              <div className="card-body" align="center">
                                <p style={{marginTop:5}}>{item.questionText}</p>
                                <p style={{marginTop:5}}>Prediction Type: {questionType}</p>
                                <div style={{margin:10}}>
                                    {item.answerImage &&
                                        <img width={100} height="auto" src={item.answerImage}/>
                                    }
                                    {item.teamOneImage &&
                                        <img width={50} height="auto" src={item.teamOneImage}/>
                                    }
                                    {item.teamTwoImage &&
                                        <img style={{marginLeft:5}} width={50} height="auto" src={item.teamTwoImage}/>
                                    }
                                </div>
                                <button className="btn btn-primary btn-lg edit-button" style={{ marginRight:5, marginBottom:10}} onClick={this.editQuestion.bind(this)} value={i}><span className="fa fa-ellipsis-v"/> Edit</button>
                                <button className="btn btn-primary btn-lg delete-button" style={{marginBottom:10}} onClick={this.deleteTicket.bind(this)} value={i}><span className="fa fa-trash-o"/> Delete</button>
                              </div>
                            </div>
                        }, this)
                      }
                    </div>
                  </div>
                </div>
              </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
              <ModalHeader toggle={this.toggle}>Add Prediction</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <div className="form-group" >
                        <label htmlFor="rewardName">Prediction</label>
                        <textarea id="currentQuestionTitle" name="currentQuestionTitle" className="form-control" value={this.state.currentQuestionTitle} onChange={this.handleChange} placeholder="Who will score the most points in the first quarter?"/>
                      </div>
                        <div className="form-group">
                            <label htmlFor="sel1">Type Of Answer:</label>
                            <select disabled={!!selectedQuestion} name="typeOfAnswer" className="form-control" id="sel1" value={this.state.typeOfAnswer} onChange={this.handleChange}>
                                <option value="slider">Slider</option>
                                <option value="write_in">Write In (Number Answers Only)</option>
                                {/*<option value="time">Time</option>*/}
                                <option value="score">Game Score</option>
                            </select>
                        </div>
                        {this.state.typeOfAnswer === "slider" &&
                            <span>
                                <div className="form-group">
                                    <label htmlFor="minTypeNumber">Min:</label>
                                    <input id="minTypeNumber" name="minTypeNumber" type="number" value={this.state.minTypeNumber} onChange={this.handleChange} className="form-control" placeholder="0"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="maxTypeNumber">Max:</label>
                                    <input id="maxTypeNumber" name="maxTypeNumber" type="number" value={this.state.maxTypeNumber} onChange={this.handleChange} className="form-control" placeholder="100"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="answerLabel">Labels:</label>
                                    <input id="answerLabel" name="answerLabel" type="text" value={this.state.answerLabel} onChange={this.handleChange} className="form-control" placeholder="yards"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="stepsNumber">Steps:</label>
                                    <input id="stepsNumber" name="stepsNumber" type="number" value={this.state.stepsNumber} onChange={this.handleChange} className="form-control" placeholder="1"/>
                                </div>
                            </span>
                        }
                        {(this.state.typeOfAnswer === "slider" || this.state.typeOfAnswer === "write_in" || this.state.typeOfAnswer === "time") &&
                            <div className="form-group" align="center">
                                <label htmlFor="rewardEmailImage" style={{width:'100%'}}>Image</label>
                                <img src={this.state.editTicketEmailImage} width="auto" height="100px" style={{display: this.state.editingTicket ? '' : 'none' }} alt=""/>
                                <Dropzone
                                    className="dropzone dz-clickable"
                                    accept="image/*"
                                    onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'emailImage')}}
                                    multiple={false}
                                    maxSize={2200000}>
                                    <div className="dz-message needsclick">
                                        <span className="fa fa-cloud-upload text-muted" style={{display: emailImagePreview.preview ? 'none' : ''}}/>
                                        <h3 style={{display: emailImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                                        <img
                                            style={{display: emailImagePreview.preview ? '' : 'none'}}
                                            src={emailImagePreview.preview}
                                            width="100px"
                                            height="auto"
                                            alt="Drop your image here"
                                        />
                                    </div>
                                </Dropzone>
                            </div>
                        }
                        {this.state.typeOfAnswer === "score" &&
                            <span>
                                <div className="form-group" align="center">
                                  <label htmlFor="teamOneImage" style={{width:'100%'}}>Team One Image</label>
                                  <img src={this.state.editTeamOneImage} width="auto" height="100px" style={{display: this.state.editingTicket ? '' : 'none' }} alt=""/>
                                  <Dropzone
                                      className="dropzone dz-clickable"
                                      accept="image/*"
                                      onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'teamOneImage')}}
                                      multiple={false}
                                      maxSize={2200000}>
                                    <div className="dz-message needsclick">
                                      <span className="fa fa-cloud-upload text-muted" style={{display: teamOneImagePreview.preview ? 'none' : ''}}/>
                                      <h3 style={{display: teamOneImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                                      <img
                                          style={{display: teamOneImagePreview.preview ? '' : 'none'}}
                                          src={teamOneImagePreview.preview}
                                          width="100px"
                                          height="auto"
                                          alt="Drop your image here"
                                      />
                                    </div>
                                  </Dropzone>
                                </div>
                                <div className="form-group" align="center">
                                  <label htmlFor="teamOneImage" style={{width:'100%'}}>Team Two Image</label>
                                  <img src={this.state.editTeamTwoImage} width="auto" height="100px" style={{display: this.state.editingTicket ? '' : 'none' }} alt=""/>
                                  <Dropzone
                                      className="dropzone dz-clickable"
                                      accept="image/*"
                                      onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'teamTwoImage')}}
                                      multiple={false}
                                      maxSize={2200000}>
                                    <div className="dz-message needsclick">
                                      <span className="fa fa-cloud-upload text-muted" style={{display: teamTwoImagePreview.preview ? 'none' : ''}}/>
                                      <h3 style={{display: teamTwoImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                                      <img
                                          style={{display: teamTwoImagePreview.preview ? '' : 'none'}}
                                          src={teamTwoImagePreview.preview}
                                          width="100px"
                                          height="auto"
                                          alt="Drop your image here"
                                      />
                                    </div>
                                  </Dropzone>
                                </div>
                            </span>
                        }
                      <div className="form-group text-center">
                        <button className="btn btn-primary btn-lg submit-button" id="submitButton" onClick={()=>this.createQuestion()}>Submit Prediction</button>
                      </div>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
         </div>
        );
    }
}

export default SetUpQuestions
