import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import RichTextEditor from 'html-verify-react-rte';
import {isEmptyHtmlEntity, isObjectEmpty} from '../utils/HelpfulFunction';

class SetUpRulesAndRegs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rulesAndRegsText: RichTextEditor.createEmptyValue(),
            howToPlayText: RichTextEditor.createEmptyValue(),
            rulesPopUpText: RichTextEditor.createEmptyValue(),
            rulesShowInAppPopUpText: RichTextEditor.createEmptyValue(),
            secondaryMandatoryCheckboxText: RichTextEditor.createEmptyValue(),
            didNotCheckRulesAndRegsBody2: RichTextEditor.createEmptyValue(),
            rulesPopUpHeader: "",
            rulesShowInAppPopUpHeader: "",
            rulesInAppButtonText: "",
            howToPlayLink: "",
            tenantRules: null,
            loading: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.howToPlayLinkRef = base.bindToState(`tenantRules/howToPlayLink`, {
            context: this,
            state: 'howToPlayLink',
        });
        this.tenantRulesRef = base.listenTo(`tenantRules`, {
            context: this,
            then(data){
                console.log(data)
                if(data.howToPlayText && data.howToPlayText !== "<p><br></p>"){
                    this.setState({
                        howToPlayText: RichTextEditor.createValueFromString(data.howToPlayText, 'html'),
                        advanced: true
                    })
                }
                if(data.howToPlayLink || data.turnOnSecondMandatoryCheckbox){
                    this.setState({
                        advanced: true,
                        turnOnSecondMandatoryCheckbox: data.turnOnSecondMandatoryCheckbox
                    })
                }
                if(data.rulesPopUpText){
                    this.setState({
                        rulesPopUpText: RichTextEditor.createValueFromString(data.rulesPopUpText, 'html'),
                        advanced: true
                    })
                }
                if(data.rulesShowInAppPopUpText){
                    this.setState({
                        rulesShowInAppPopUpText: RichTextEditor.createValueFromString(data.rulesShowInAppPopUpText, 'html')
                    })
                }
                if(data.rulesAndRegsText && data.rulesAndRegsText !== "<p><br></p>"){
                    this.setState({rulesAndRegsText: RichTextEditor.createValueFromString(data.rulesAndRegsText, 'html'),})
                }
                if(data.secondaryMandatoryCheckboxText && data.secondaryMandatoryCheckboxText !== "<p><br></p>"){
                    this.setState({secondaryMandatoryCheckboxText: RichTextEditor.createValueFromString(data.secondaryMandatoryCheckboxText, 'html'),})
                }
                if(data.didNotCheckRulesAndRegsBody2 && data.didNotCheckRulesAndRegsBody2 !== "<p><br></p>"){
                    this.setState({didNotCheckRulesAndRegsBody2: RichTextEditor.createValueFromString(data.didNotCheckRulesAndRegsBody2, 'html'),})
                }
                if(data.rulesShowInApp){
                    this.setState({
                        rulesShowInApp: true,
                        advanced: true
                    })
                }
                this.setState({
                    loading: false

                })
            }
        });
        this.rulesPopUpHeaderRef = base.bindToState(`tenantRules/rulesPopUpHeader`, {
            context: this,
            state: 'rulesPopUpHeader',
        });
        this.rulesInAppButtonTextRef = base.bindToState(`tenantRules/rulesInAppButtonText`, {
            context: this,
            state: 'rulesInAppButtonText',
        });
        this.rulesShowInAppPopUpHeaderRef = base.bindToState(`tenantRules/rulesShowInAppPopUpHeader`, {
            context: this,
            state: 'rulesShowInAppPopUpHeader',
        });
    }

    componentWillUnmount() {
        base.removeBinding(this.howToPlayLinkRef);
        base.removeBinding(this.rulesPopUpHeaderRef);
        base.removeBinding(this.rulesInAppButtonTextRef);
        base.removeBinding(this.rulesShowInAppPopUpHeaderRef);
        base.removeBinding(this.tenantRulesRef);
    }

    handleRichTextChange = (name, text) => {
        this.setState({ [name]:  text});
    }

    handleSubmit(event) {
        event.preventDefault();
        let rulesAndRegsText = this.state.rulesAndRegsText || "";
        if(rulesAndRegsText){
            rulesAndRegsText = rulesAndRegsText.toString('html');
        }
        let rulesPopUpHeader = this.state.rulesPopUpHeader;
        let howToPlayLink = this.state.howToPlayLink;
        let rulesPopUpText = this.state.rulesPopUpText.toString('html');
        let howToPlayText = this.state.howToPlayText.toString('html');
        let rulesShowInAppPopUpText = this.state.rulesShowInAppPopUpText.toString('html');
        let didNotCheckRulesAndRegsBody2 = this.state.didNotCheckRulesAndRegsBody2.toString('html');
        let secondaryMandatoryCheckboxText = this.state.secondaryMandatoryCheckboxText.toString('html');
        let rulesShowInAppPopUpHeader = this.state.rulesShowInAppPopUpHeader;
        let rulesInAppButtonText = this.state.rulesInAppButtonText;
        let turnOnSecondMandatoryCheckbox = this.state.turnOnSecondMandatoryCheckbox || false;
        let didNotCheckRulesAndRegsHeader2 = this.state.didNotCheckRulesAndRegsHeader2 || '';
        if(rulesInAppButtonText && rulesInAppButtonText.length > 18){
            swal({
                title: "Input Error",
                text: "Button Text Cannot Be Longer Then 18 Characters",
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        let rulesShowInApp = this.state.rulesShowInApp || false;
        if(isEmptyHtmlEntity(howToPlayText)){
            howToPlayText = "";
        }
        if(isEmptyHtmlEntity(rulesPopUpText)){
            rulesPopUpText = "";
        }
        if(isEmptyHtmlEntity(rulesShowInAppPopUpText)){
            rulesShowInAppPopUpText = "";
        }
        if(isEmptyHtmlEntity(rulesAndRegsText)){
            rulesAndRegsText = "";
        }
        if(isEmptyHtmlEntity(didNotCheckRulesAndRegsBody2)){
            didNotCheckRulesAndRegsBody2 = "";
        }
        if(isEmptyHtmlEntity(secondaryMandatoryCheckboxText)){
            secondaryMandatoryCheckboxText = "";
        }
        let updateRulesObject = {
            "rulesAndRegsText": rulesAndRegsText, "howToPlayLink": howToPlayLink,
            "howToPlayText": howToPlayText, "rulesPopUpText": rulesPopUpText, "rulesPopUpHeader":rulesPopUpHeader,
            "rulesShowInAppPopUpText": rulesShowInAppPopUpText, "rulesShowInAppPopUpHeader": rulesShowInAppPopUpHeader,
            "rulesInAppButtonText": rulesInAppButtonText, "rulesShowInApp": rulesShowInApp, turnOnSecondMandatoryCheckbox,
            didNotCheckRulesAndRegsHeader2, didNotCheckRulesAndRegsBody2, secondaryMandatoryCheckboxText
        }
        this.setState({loading:true})
        let vm = this;
        base.post("tenantRules/", {
            data: updateRulesObject,
            then(err){
                vm.setState({loading:false})
                if(!err){
                    swal({
                        title: 'Rules and Regs Updated!',
                        type: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: true,
                        timer: 10000
                    })
                } else {
                    swal({
                        title: 'There was some error!',
                        text: 'Try again and if the problem persists try logging out and logging back in',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    })
                    console.log(err)
                }
            }
        })
    }

    handleChange (evt) {
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    render() {
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        let rulesAndRegsText = this.state.rulesAndRegsText;
        let rulesPopUpHeader = this.state.rulesPopUpHeader;
        let rulesPopUpText = this.state.rulesPopUpText;
        let rulesInAppButtonText = this.state.rulesInAppButtonText;
        let rulesShowInAppPopUpHeader = this.state.rulesShowInAppPopUpHeader;
        let rulesShowInAppPopUpText = this.state.rulesShowInAppPopUpText;
        let secondaryMandatoryCheckboxText = this.state.secondaryMandatoryCheckboxText;
        let didNotCheckRulesAndRegsBody2 = this.state.didNotCheckRulesAndRegsBody2;
        let turnOnSecondMandatoryCheckbox = this.state.turnOnSecondMandatoryCheckbox || false;
        let didNotCheckRulesAndRegsHeader2 = this.state.didNotCheckRulesAndRegsHeader2;
        let howToPlayLink = this.state.howToPlayLink;
        let howToPlayText = this.state.howToPlayText;
        if(isObjectEmpty(rulesPopUpText)){
            rulesPopUpText = ""
        }
        if(isObjectEmpty(rulesAndRegsText)){
            rulesAndRegsText = ""
        }
        if(isObjectEmpty(howToPlayLink)){
            howToPlayLink = ""
        }
        if(isObjectEmpty(rulesPopUpHeader)){
            rulesPopUpHeader = ""
        }
        if(isObjectEmpty(rulesShowInAppPopUpHeader)){
            rulesShowInAppPopUpHeader = ""
        }
        if(isObjectEmpty(rulesShowInAppPopUpText)){
            rulesShowInAppPopUpText = ""
        }
        if(isObjectEmpty(rulesInAppButtonText)){
            rulesInAppButtonText = ""
        }
        if(isObjectEmpty(secondaryMandatoryCheckboxText)){
            secondaryMandatoryCheckboxText = ""
        }
        if(isObjectEmpty(didNotCheckRulesAndRegsBody2)){
            didNotCheckRulesAndRegsBody2 = ""
        }
        const advanced = this.state.advanced || false;
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
                <SideMenu/>
                <TopMenu/>
                <div className="admin-main-panel">
                    <div className="container-out" style={{width:'45%', float:'left'}}>
                        <div className="admin-form-box">
                            <form onSubmit={this.handleSubmit} id="create-game-form">
                                <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                                <div className="form-group">
                                    <label htmlFor="rulesAndRegsText">Rules And Regs Text</label>
                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This text will appear AFTER the mandatory confirm rules and regs button on the login form<br/><strong>Example:</strong> Welcome! To play, please confirm that you agree with the rules and regs</p>
                                    <RichTextEditor id="rulesAndRegsText" name="rulesAndRegsText" toolbarConfig={toolbarConfig} value={rulesAndRegsText} onChange={(text) => this.handleRichTextChange("rulesAndRegsText", text)}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="showAdvancedSettings">Advanced</label>
                                    <br/>
                                    <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                                </div>
                                {advanced &&
                                <>
                                    <div className="form-group">
                                        <label htmlFor="howToPlayLink">How To Play Link</label>
                                        <p className="text-muted2" style={{fontSize:'10px'}}>This is where you can teach fans how to play</p>
                                        <input id="howToPlayLink" name="howToPlayLink" type="url" className="form-control" value={howToPlayLink} onChange={this.handleChange} placeholder="https://ourgameplay.com"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="howToPlayText">How To Play Text (replaces link if filled)</label>
                                        <p className="text-muted2" style={{fontSize:'10px'}}>This is where you can teach fans how to play</p>
                                        <RichTextEditor id="howToPlayText" name="howToPlayText" toolbarConfig={toolbarConfig} value={this.state.howToPlayText} onChange={(text) => this.handleRichTextChange("howToPlayText", text)}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="rulesPopUpHeader">Rules & Regs Pop Up Header (Optional)</label>
                                        <p className="text-muted2" style={{fontSize:'10px'}}>The header of the in-app pop up containing rules</p>
                                        <input id="rulesPopUpHeader" name="rulesPopUpHeader" type="text" className="form-control" value={rulesPopUpHeader} onChange={this.handleChange} placeholder="Rules & Regs"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="rulesPopUpText">Rules & Regs Pop Up Text</label>
                                        <p className="text-muted2" style={{fontSize:'10px'}}>The text of the in-app pop up. When this is filled in it will replace the rules and regs link and the text here will show up in a pop up.</p>
                                        <RichTextEditor id="rulesPopUpText" name="rulesPopUpText" toolbarConfig={toolbarConfig} value={rulesPopUpText} onChange={(text) => this.handleRichTextChange("rulesPopUpText", text)}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="rulesShowInApp">Show Rules And Regs After Sign Up Screen</label>
                                        <br/>
                                        <input type="checkbox" checked={this.state.rulesShowInApp} id="rulesShowInApp" name="rulesShowInApp" onChange={this.handleChange}/>
                                    </div>
                                    {this.state.rulesShowInApp &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="rulesInAppButtonText">Button Text</label>
                                                <p className="text-muted2" style={{fontSize:'10px'}}>The text of the button that links to Rules & Regs</p>
                                                <input id="rulesInAppButtonText" name="rulesInAppButtonText" type="text" className="form-control" value={rulesInAppButtonText} onChange={this.handleChange} placeholder="Rules & Regs"/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="rulesShowInAppPopUpHeader">Pop Up After Sign Up (Optional)</label>
                                                <p className="text-muted2" style={{fontSize:'10px'}}>The header of the in-app pop up</p>
                                                <input id="rulesShowInAppPopUpHeader" name="rulesShowInAppPopUpHeader" type="text" className="form-control" value={rulesShowInAppPopUpHeader} onChange={this.handleChange} placeholder="Rules & Regs"/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="rulesShowInAppPopUpText">Rules & Regs Pop Up Text</label>
                                                <p className="text-muted2" style={{fontSize:'10px'}}>The body text of the pop up</p>
                                                <RichTextEditor id="rulesShowInAppPopUpText" name="rulesShowInAppPopUpText" toolbarConfig={toolbarConfig} value={rulesShowInAppPopUpText} onChange={(text) => this.handleRichTextChange("rulesShowInAppPopUpText", text)}/>
                                            </div>
                                        </>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="turnOnSecondMandatoryCheckbox">Second Mandatory Rules & Regs Checkbox</label>
                                        <br/>
                                        <input type="checkbox" checked={turnOnSecondMandatoryCheckbox} id="turnOnSecondMandatoryCheckbox" name="turnOnSecondMandatoryCheckbox" onChange={this.handleChange}/>
                                    </div>
                                    {turnOnSecondMandatoryCheckbox &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="secondaryMandatoryCheckboxText">Rules And Regs Text</label>
                                                <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This text will appear AFTER the second mandatory confirm rules and regs button on the login form<br/><strong>Example:</strong> Welcome! To play, please confirm that you agree with the second rules and regs</p>
                                                <RichTextEditor
                                                    toolbarConfig={toolbarConfig}
                                                    id="secondaryMandatoryCheckboxText"
                                                    name="secondaryMandatoryCheckboxText"
                                                    value={secondaryMandatoryCheckboxText}
                                                    onChange={(text) => this.handleRichTextChange("secondaryMandatoryCheckboxText", text)}
                                                    placeholder=""
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="didNotCheckRulesAndRegsHeader2">Did Not Check Rules And Regs Header</label>
                                                <p className="text-muted2" style={{fontSize:'10px'}}>The header of the error when the Rules & Regs checkbox isn't error</p>
                                                <input id="didNotCheckRulesAndRegsHeader2" name="didNotCheckRulesAndRegsHeader2" type="text" className="form-control" value={didNotCheckRulesAndRegsHeader2} onChange={this.handleChange} placeholder="Input Error"/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="didNotCheckRulesAndRegsBody2">Did Not Check Rules And Regs Body</label>
                                                <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is the body message of the pop up</p>
                                                <RichTextEditor
                                                    toolbarConfig={toolbarConfig}
                                                    id="didNotCheckRulesAndRegsBody2"
                                                    name="didNotCheckRulesAndRegsBody2"
                                                    value={didNotCheckRulesAndRegsBody2}
                                                    onChange={(text) => this.handleRichTextChange("didNotCheckRulesAndRegsBody2", text)}
                                                    placeholder=""
                                                />
                                            </div>
                                        </>
                                    }
                                </>
                                }
                            </form>
                        </div>
                    </div>
                    <div className="container-out" style={{width:'45%', float:'right', marginRight:'20px'}}>
                        <div className="admin-form-box" style={{border:'1px solid black', backgroundColor:'white'}}>
                            <p style={{fontSize:'20px', fontWeight:'bold'}}>Why do I need rules & regulations?</p>
                            <p className="text-muted2">While each state, country, and province is different, you will want to make sure you are compliant with all contest rules and laws.  It is your responsibility to update, maintain, & host your rules and regulations tab.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SetUpRulesAndRegs;
